import { useNavigate } from "react-router-dom";
import Styles from "./LandingPage.module.css";
import { PiInstagramLogoBold } from "react-icons/pi";
import { RiFacebookFill, RiMoneyDollarCircleFill } from "react-icons/ri";
import AccordionItem from "../../Components/AccordianItem";
import { FAQS_TRT, FAQS_TRT2 } from "../../Model/FaqModel";
import { useEffect, useRef, useState } from "react";
import Testimonial from "../Home/Testimonial";
import { Testimonials_Model } from "../../Model/HomeModel";
import Loading from "../../Components/Loading";
import {
  FaCalendarAlt,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaRegDotCircle,
} from "react-icons/fa";

import { IoHomeOutline } from "react-icons/io5";
import { IoIosCall, IoIosCloseCircle } from "react-icons/io";
import { DetailsFormJSon, ValidatorForm2 } from "./LandingPageModel";
import { useDispatch, useSelector } from "react-redux";
import "./Landing.css";
import {
  TEST_BOOKING_FAILED,
  TEST_BOOKING_RESET,
} from "../../Constant/ContactConstant";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import { AiFillSafetyCertificate } from "react-icons/ai";
import api from "../../Core/api";
import { MdAccessTimeFilled } from "react-icons/md";

export default function LandingPage2() {
  const Navigate = useNavigate();
  const [active, setActive] = useState(null);
  const [DetailsForm, setDetailsForm] = useState({
    ...DetailsFormJSon,
    Amount: "₹ 99.00",
  });

  const [Auth, setAuth] = useState({
    loading: false,
    success: false,
    error: false,
  });
  const TestmonialRef = useRef();
  const ScrollRef = useRef();
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((s) => s.Test_Book_Reducer);
  const [index, setIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("fadeInUp");
  const Heading = [1, 2, 3];

  const [createSec, setcreateSec] = useState(false);

  // /student/cart add cart
  // "packageId": "any",
  // "Timings": "any",
  // "CustomTimings": "any",
  // "classMode": "any",
  // "CourseId": "any"

  // ------

  // /student/cart/withemail add to cart
  // {
  //   "packageId": "any",
  //   "EmailAddress": "any",
  //   "Timings": "any",
  //   "CustomTimings": "any",
  //   "classMode": "any"
  // }

  // /student/order  /student/order/withemail

  // {
  //   "amount": "any",
  //   "PackageId": "any",  //with email EmailAddress:""
  //   "CouponUsed": "any"
  // }

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setAnimationClass("");
        setIndex((index) => (index + 1) % Heading.length);
        setAnimationClass("fadeInUp");
      },
      5000 // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleChange = (e) => {
    if (
      e.target.name == "phone" &&
      (e.target.value?.length > 10 || e.target.value.includes(" "))
    ) {
    } else {
      setDetailsForm({ ...DetailsForm, [e.target.name]: e.target.value });
    }
  };

  const Scroll = () => {
    setcreateSec(true);
  };

  const handleCancel = () => {
    setcreateSec(false);
    setDetailsForm({ ...DetailsFormJSon, Amount: "₹ 99.00" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const Validate = ValidatorForm2(DetailsForm);
    if (Validate == "success") {
      setAuth({ ...Auth, loading: true });
      try {
        const { data } = await api.post("/landing/contact", {
          ...DetailsForm,
          url: window.location.href,
        });
        if (data?.Result) {
          const data2 = await api.post("/student/cart/withemail", {
            packageId: "67931728339171f954f77854", // 6793cc90a49569e351d56277 for production , 67931728339171f954f77854 for staging
            EmailAddress: DetailsForm?.email,
            Timings: "",
            CustomTimings: "",
            classMode: "",
          });

          if (data2?.data?.Result) {
            const data3 = await api.post("/student/order/withemail", {
              amount: 99,
              PackageId: "67931728339171f954f77854", // 6793cc90a49569e351d56277 for production , 67931728339171f954f77854 for staging
              EmailAddress: DetailsForm?.email,
              CouponUsed: "",
            });

            setAuth({ ...Auth, loading: false, success: true });
            if (data3?.data?.Result) {
              window.location.href = data3?.data?.Result;
            }
          }
        }
      } catch (error) {
        setAuth({
          ...Auth,
          loading: false,
          error:
            error.response && error.response.data.Result
              ? error.response.data.Result
              : error.message,
        });
      }
    } else {
      dispatch({ type: TEST_BOOKING_FAILED, payload: Validate });
    }
  };

  useEffect(() => {
    if (Auth.success) {
      setTimeout(() => {
        setDetailsForm({ ...DetailsFormJSon });
        dispatch({ type: TEST_BOOKING_RESET });
      }, 5000);
    }
  }, [Auth.success, dispatch]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     document.getElementById("stat1").classList.add("show");
  //     document.getElementById("JoinStat1").classList.add("show");
  //   }, 500);

  //   setTimeout(() => {
  //     document.getElementById("stat2").classList.add("show");
  //     document.getElementById("JoinStat2").classList.add("show");
  //   }, 1500);

  //   setTimeout(() => {
  //     document.getElementById("stat3").classList.add("show");
  //     document.getElementById("JoinStat3").classList.add("show");
  //   }, 2500);

  //   setTimeout(() => {
  //     document.getElementById("JoinStat4").classList.add("show");
  //   }, 3000);
  // }, []);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Book Reality Test for IELTS & PTE | TRT | Testifyi Reality Test"
        />
        <meta
          name="description"
          content="Discover your IELTS/PTE level in a real test environment by Testifyi. Perfect for students aspiring to study abroad! Affordable: At just ₹99."
        />
        <meta
          name="keywords"
          content="Book Reality Test for IELTS & PTE, IELTS Reality Test, PTE Reality Test, IELTS & PTE reality test, testifyi reality test, TRT test"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Testifyi" />
        <title>
          Book Reality Test for IELTS & PTE | TRT | Testifyi Reality Test{" "}
        </title>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is TRT: Testifyi Reality Test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "TRT is a specially designed mock IELTS/PTE test that allows students to: Experience the real IELTS/PTE exam environment. Assess their current skill level. Receive detailed feedback to improve performance in Listening, Reading, Writing, and Speaking"
    }
  },{
    "@type": "Question",
    "name": "What is the cost of the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The test is priced at just ₹99—a highly affordable opportunity to experience a real exam-like environment."
    }
  },{
    "@type": "Question",
    "name": "When and where will the event be conducted?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The event is scheduled for January 18, 2025, at Nehru Place, Delhi, from 10:00 AM to 5:00 PM."
    }
  },{
    "@type": "Question",
    "name": "What documents should I bring along?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Please bring the following: A valid photo ID (Aadhaar card, passport, or student ID). Your registration confirmation email or message. Basic stationery like pens, pencils, and erasers."
    }
  },{
    "@type": "Question",
    "name": "What is included in the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The test includes all four sections of the IELTS/PTE exam: Listening, Reading, Writing, and Speaking. You’ll get a complete experience of the actual test format."
    }
  },{
    "@type": "Question",
    "name": "When will my Speaking module test be conducted?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Speaking test will be conducted on the same day as the event. Specific time slots will be assigned 2 days prior to the test day."
    }
  },{
    "@type": "Question",
    "name": "Will I be notified about my Speaking test time slot?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, your Speaking test time slot will be shared with you 2 days prior to the test day."
    }
  },{
    "@type": "Question",
    "name": "Who can participate in this event?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "This test is ideal for students preparing for IELTS/PTE, whether you're a beginner or looking to refine your skills before the actual exam."
    }
  },{
    "@type": "Question",
    "name": "Will I receive feedback on my performance?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, our IELTS/PTE experts will provide personalized feedback after the test, helping you understand your strengths and areas for improvement."
    }
  },{
    "@type": "Question",
    "name": "How can I register for the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Click the [Register Now for ₹99] button, fill in your details, and a Testifyi coordinator will reach out to help you secure your spot."
    }
  },{
    "@type": "Question",
    "name": "What happens if I miss the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Unfortunately, the fee is non-refundable. However, you may contact us to explore any future test opportunities."
    }
  }]
}`}
        </script>
      </Helmet>

      {/* Header  start*/}

      {loading || Auth.loading ? <Loading /> : ""}

      <header>
        <div className={Styles.Header}>
          <div>
            <img
              // onClick={() => Navigate("/")}
              src="/assets/image/Logos/Testify_White_Logo.svg"
              alt="logo"
            />
          </div>
          {/* <div>
            <button className={Styles.ActionBtn}>Book</button>
          </div> */}
          <div>
            <p
              style={{
                display: "flex",
                gap: "8px",
                color: "#007bff",
                fontSize: "25px",
              }}
            >
              <span
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/testifyi.india?igsh=c3ZpN2N2ZDg4cWVm"
                  )
                }
              >
                <PiInstagramLogoBold />
              </span>
              <span
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/testifyi.india?mibextid=LQQJ4d"
                  )
                }
              >
                <RiFacebookFill />
              </span>
              {/* <span>
                <TiSocialLinkedin />{" "}
              </span><FaMapMarkedAlt />
              <span>
                <BiLogoTwitter />
              </span> */}
            </p>{" "}
          </div>
        </div>
      </header>
      {/* Header end */}

      {/* Banner form Section start */}
      <section className={Styles.Margin2}>
        <div className={Styles.BannerTop}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent:
                  window.innerWidth <= 480 ? "flex-start" : "space-between",
                flexDirection: window.innerWidth <= 480 ? "column" : "row",
                // alignItems: "center",
              }}
            ></div>
            <div id="container2" className={Styles.Top_Webniner}>
              <div>
                <h2>
                  <span
                    style={{
                      backgroundColor: "#0b3eff",
                      padding: "5px 7px",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    Master
                  </span>{" "}
                  Your IELTS Score
                </h2>
              </div>
              <div className={Styles.Webnier_Top}>
                <div>
                  <p>
                    <span>
                      <FaCalendarAlt />
                    </span>
                    <span>
                      31<sup>st</sup> Jan, 7 PM
                    </span>
                    <span>
                      <MdAccessTimeFilled />
                    </span>
                    <span>90 mins</span>
                  </p>
                </div>
                <div>
                  <h4>
                    <RiMoneyDollarCircleFill />
                  </h4>
                  <h4>Get 50% Off on full IELTS course</h4>
                </div>
              </div>
              <div>
                <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                  Register Now for ₹99/-
                </button>
              </div>
            </div>
          </div>
          {/* Form Details Start */}
          <div className={Styles.TopBanner_1} ref={ScrollRef}>
            <img
              src="/assets/image/Testifyi-webnier-top.png"
              alt="top_banner"
            />
          </div>

          {/* Form Details end */}
        </div>
      </section>
      {/* Banner form Section end */}

      {/* Testimonials start */}
      <section style={{ background: "#f4f9ff" }} className={Styles.Margin2}>
        <div>
          <h1 className={Styles.Heading}>
            <span className={Styles.Font_Rajdhani}>
              {" "}
              Hear From Our Students
            </span>
          </h1>
        </div>

        <Testimonial
          TestmonialRef={TestmonialRef}
          Testimonials_Model={Testimonials_Model}
        />
        <div className={Styles.Center}>
          <button className={Styles.ActionBtn} onClick={() => Scroll()}>
            Join the webinar today
          </button>
        </div>
      </section>
      {/* Testimonials end */}

      {/* Video Section Start */}
      {/* <section>
        <AnimatePresence mode="wait">
          <motion.div
            className="section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.5 }}
          >
            <div className={Styles.Margin2}>
              <div className="trust-section">
                <div>
                  <h1 className={Styles.Font_Rajdhani}>
                    Trusted by
                    <span style={{ color: "#0b3eff" }}> Thousands</span> of Test
                    Takers
                  </h1>
                  <p>
                    Over <strong>300+ Tests Conducted</strong> and{" "}
                    <strong>15000+ Test Takers</strong>. Join us to experience
                    the real test environment!
                  </p>
                </div>
                <div className="trust-stats">
                  <div className="stat-box fade-animation" id="stat1">
                    <h3>300+</h3>
                    <span>Tests Conducted</span>
                  </div>
                  <div className="stat-box fade-animation" id="stat2">
                    <h3>15000+</h3>
                    <span>Test Takers</span>
                  </div>
                  <div className="stat-box fade-animation" id="stat3">
                    <h3>100%</h3>
                    <span>Satisfied Students</span>
                  </div>
                </div>
              </div>
              <div className={Styles.VideoSection}>
                <video
                  preload="auto"
                  autobuffer
                  muted
                  width="100%"
                  height="100%"
                  autoPlay
                  loop
                  playsInline
                >
                  <source src="/assets/image/web-vd.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </section> */}
      {/* Video section End */}

      {/* why Join us start */}
      {/* <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div>
              <h1 className={Styles.Font_Rajdhani}>
                What is the TRT (
                <span style={{ color: "#0b3eff" }}> Testifyi</span> Reality
                Test)?
              </h1>
              <p>
                TRT is a specially designed in-house event by Testifyi to help
                aspiring IELTS/PTE students.
              </p>
            </div>

            <div className="trt-grid">
              <div className="trt-card fade-animation1" id="JoinStat1">
                <div className="icon">
                  <i className="fas fa-chalkboard-teacher"></i>
                </div>
                <h3>Real Test Environment</h3>
                <p>
                  Experience the real IELTS/PTE test environment to reduce
                  test-day anxiety.
                </p>
              </div>
              <div className="trt-card fade-animation1" id="JoinStat2">
                <div className="icon">
                  <i className="fas fa-chart-line"></i>
                </div>
                <h3>Skill Assessment</h3>
                <p>
                  Understand your current skill level with a detailed
                  performance analysis.
                </p>
              </div>
              <div className="trt-card fade-animation1" id="JoinStat3">
                <div className="icon">
                  <i className="fas fa-lightbulb"></i>
                </div>
                <h3>Insights & Feedback</h3>
                <p>
                  Receive expert insights into areas of improvement to maximize
                  your score.
                </p>
              </div>
              <div className="trt-card fade-animation1" id="JoinStat4">
                <div className="icon">
                  <i className="fas fa-book-open"></i>
                </div>
                <h3>All IELTS/PTE Sections</h3>
                <p>
                  Prepare for Listening, Reading, Writing, and Speaking—just
                  like the real exam!
                </p>
              </div>
            </div>

            <div className="trt-cta">
              <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                Sign Up Today—Limited Seats Available
              </button>
            </div>
          </div>
        </div>
      </section> */}
      {/* why Join us end */}

      {/* why Join us start */}
      <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div>
              <h1 className={Styles.Font_Rajdhani}>
                What Will You
                <span style={{ color: "#0b3eff" }}> Learn </span> in 90 Minutes?
              </h1>
              <p>
                Discover actionable strategies and practical techniques tailored
                to each IELTS section, helping you overcome challenges and boost
                your band score.
              </p>
            </div>
            <div className="Timeline_Webnier">
              <div class="timeline-container">
                <div class="timeline">
                  <div class="timeline-item">
                    <div class="timeline-pointer">1</div>
                    <div class="timeline-content">
                      <div class="time">00 mins - 15 mins</div>
                      <h3>Mastering Listening</h3>
                      <p>
                        Learn how to tackle accents, manage time pressure, and
                        focus better during the listening test. Insider tips to
                        track answers efficiently and avoid zoning out or
                        missing key details.
                      </p>
                    </div>
                  </div>
                  <div class="timeline-item">
                    <div class="timeline-pointer">2</div>
                    <div class="timeline-content">
                      <div class="time">15 mins - 30 mins</div>
                      <h3>Reading Like a Pro</h3>
                      <p>
                        Decode tricky "True/False/Not Given" questions,
                        understand paraphrasing, and manage time for lengthy
                        passages. Strategies for matching table-type questions
                        and strong inference skills.
                      </p>
                    </div>
                  </div>
                  <div class="timeline-item">
                    <div class="timeline-pointer">3</div>
                    <div class="timeline-content">
                      <div class="time">30 mins - 45 mins</div>
                      <h3>Speaking Confidence Unlocked</h3>
                      <p>
                        Eliminate hesitation and boost fluency with tips on
                        grammar, pronunciation, and vocabulary. Handle topic
                        cards confidently and give relevant, detailed answers.
                      </p>
                    </div>
                  </div>
                  <div class="timeline-item">
                    <div class="timeline-pointer">4</div>
                    <div class="timeline-content">
                      <div class="time">45 mins - 60 mins</div>
                      <h3>Cracking Writing Tasks</h3>
                      <p>
                        Organize ideas, paraphrase effectively, and craft
                        well-structured essays. Avoid common mistakes like going
                        off-topic or overcomplicating ideas.
                      </p>
                    </div>
                  </div>
                  <div class="timeline-item">
                    <div class="timeline-pointer">5</div>
                    <div class="timeline-content">
                      <div class="time">60 mins - 75 mins</div>
                      <h3>Time Management Hacks</h3>
                      <p>
                        Advanced time management techniques for all sections,
                        with real-life scenarios and practical tools to optimize
                        performance.
                      </p>
                    </div>
                  </div>
                  <div class="timeline-item">
                    <div class="timeline-pointer">6</div>
                    <div class="timeline-content">
                      <div class="time">75 mins - 90 mins</div>
                      <h3>Real Student Success Stories</h3>
                      <p>
                        See how students improved their scores using these
                        techniques. Gain motivation and clarity for your own
                        test journey.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={Styles.Why_Should_Section}>
              <div className={Styles.Why_Sub_1}>
                <div>
                  <i className="fas fa-chalkboard-teacher"></i>
                </div>
                <div>
                  <h3>Real Test Experience</h3>
                  <p>
                    Feel the pressure and format of an actual IELTS/PTE test.
                  </p>
                </div>
              </div>

              <div className={Styles.Why_Sub_2}>
                <div>
                  <h3>Affordable Fee</h3>
                  <p>At just ₹99, it's an unmissable opportunity.</p>
                </div>
                <div>
                  <i className="fas fa-wallet"></i>
                </div>
              </div>

              <div className={Styles.Why_Sub_1}>
                <div>
                  <i className="fas fa-chart-line"></i>
                </div>
                <div>
                  <h3>Skill Analysis</h3>
                  <p>Know where you stand and what to improve.</p>
                </div>
              </div>

              <div className={Styles.Why_Sub_2}>
                <div>
                  <h3>Expert Feedback</h3>
                  <p>Personalized insights from IELTS/PTE experts.</p>
                </div>
                <div>
                  <i className="fas fa-user-check"></i>
                </div>
              </div>
            </div> */}
            {/* <div className="trt-cta">
              <button className={Styles.ActionBtn}>
                Sign Up Today—Limited Seats Available
              </button>
            </div> */}
          </div>
        </div>
      </section>

      {/* why Join us end */}

      {/* Event Details css start */}
      <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div>
              <h1 className={Styles.Font_Rajdhani}>
                Unlock
                <span style={{ color: "#0b3eff" }}> Exclusive Offers </span>
                After Attending Our IELTS Webinar!
              </h1>
              <p>
                We’re excited to offer you an incredible deal once you register
                and attend our IELTS webinar
              </p>
            </div>
            <div className={Styles.UnlockOffer}>
              <div>
                <h5>🎓 50% Off on Our Full IELTS Course</h5>
                <p>
                  After the webinar, you'll get a <strong> 50% discount</strong>{" "}
                  on our comprehensive IELTS course—designed to take your
                  preparation to the next level!
                </p>
                <h5>💰 100% Refund on IELTS Fees</h5>
                <p>
                  Here’s the best part: If you choose to apply for study abroad
                  programs through us,
                  <strong>we’ll refund your full IELTS fees! </strong> No hidden
                  terms—just a straightforward refund when you proceed with one of our
                  study abroad partners.
                </p>
              </div>
              <div className={Styles.UnlockOfferImage}>
                <img src="/assets/image/webnier_unlock-offer.jpg" alt="unlock offer" />
              </div>
            </div>
            <div className="trt-cta">
              <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                Book Your Seat Now
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Event Details css end */}

      {/* FAQs satrt */}
      <section style={{ background: "#ffffff" }} className=".Faqs">
        <div className={Styles.Margin2}>
          <div className="container-fluid mt-5 mb-5">
            <div className="card-body_Faq">
              <div className={Styles.Form_Heading}>
                <h1 className="">
                  Frequently Asked{" "}
                  <span style={{ color: "#0b3eff" }}>Questions</span>
                </h1>
                <p style={{ textAlign: "center" }}>
                  Got Questions? We’ve Got Answers!
                </p>
              </div>
              <div className="FAQ_ALL">
                {FAQS_TRT2.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                    />
                  );
                })}
              </div>
              {/* <div className={Styles.Center}>
                <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                  Join the Test Today
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* FAQs end */}

      {/* Payment Form start */}

      {createSec ? (
        <div
          id="CreatContact"
          style={{ width: "100%" }}
          className={"overlay-note middle-hand w100 BookDemo Payment_landing"}
        >
          <div className="container" id="booking-container">
            <h1>Payment Details</h1>
            <div onClick={() => handleCancel()} className="closeBtn_Book">
              <span>
                <IoIosCloseCircle />
              </span>
            </div>
            <form
              id="appointment-form"
              onSubmit={handleSubmit}
              className="Form_Appointment landingPayment"
            >
              <div>
                <div className="form-group">
                  <label for="amount">Amount</label>
                  <input
                    id="amount"
                    className="Amount99"
                    type="text"
                    name="Amount"
                    value={DetailsForm?.Amount}
                    disabled={true}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
              </div>

              <div>
                <div className="form-group">
                  <label for="name">Name</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={DetailsForm?.name}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
              </div>

              <div>
                <div className="form-group">
                  <label for="email">Email Address</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={DetailsForm?.email}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
              </div>

              <div>
                <div className="form-group">
                  <label for="phone">Phone No</label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={DetailsForm?.phone}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
              </div>
              <div>
                {error || Auth.error ? (
                  <p style={{ color: "red" }}> {error || Auth.error}</p>
                ) : Auth?.success ? (
                  <p style={{ color: "green" }}>{Auth?.success}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="Fotter_payment">
                <div>
                  <img
                    src="/assets/image/pay_methods_branding.png"
                    alt="pay_methods_branding"
                  />
                </div>
                <div className="Book_A_demo_btn">
                  <button type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Pay   ₹ 99.00"}
                  </button>
                </div>
              </div>
              <div
                className="Safe_Secure"
                style={{ padding: "0", margin: "0" }}
              >
                <p style={{ paddingTop: "8px", margin: "0" }}>
                  <span>
                    <AiFillSafetyCertificate />
                  </span>
                  <span>Safe and Secure Payments 100%.</span>
                </p>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Payment Form end */}

      {/* Footer start */}
      <footer className={Styles.Footer} style={{ marginBottom: "80px" }}>
        <div>
          {/* <div>
            <div>
              <img
                onClick={() => Navigate("/")}
                src="/assets/image/Logos/Testifyi_logo1.jpg"
                alt="logo"
              />
              <p>
                Facilitating students acquire perfect-fit education placements
                that ultimately changes their lives!
              </p>

         
            </div>
            <hr className={Styles.Hr1} />
            <div>
              <h3>My Profile</h3>
              <p onClick={() => Navigate("/signup", { replace: true })}>
                Create Account
              </p>
              <p onClick={() => Navigate("/login", { replace: true })}>
                Login Now
              </p>
              <p onClick={() => Navigate("/contact-us")}>Contact Us</p>{" "}
              <p onClick={() => Navigate("/about-us")}>About Us</p>
            </div>
            <hr className={Styles.Hr1} />
            <div>
              <h3>Important Links</h3>
              <p onClick={() => Navigate("/Course")}>Our Courses</p>
           
              <p onClick={() => Navigate("/dashboard")}>Dashboard</p>
              <p onClick={() => Navigate("/terms-and-conditions")}>
                Terms And Conditions
              </p>
              <p onClick={() => Navigate("/privacy-policy")}>Privacy Policy</p>
            </div>
            <hr className={Styles.Hr1} />
            <div>
              <h3>Contact Us</h3>
              <p style={{ display: "flex", gap: "8px", fontSize: "13px" }}>
                <span style={{ color: "white" }}>
                  <IoHomeOutline />{" "}
                </span>

                <span style={{ fontSize: "14px" }}>
                  Address : 1409-1412, 14th Floor, 38, Ansal Tower, Nehru Place,
                  New Delhi, Delhi 110019
                </span>
              </p>

              <p style={{ display: "flex", gap: "8px", fontSize: "13px" }}>
                <span style={{ color: "white" }}>
                  <IoIosCall />
                </span>
                <span style={{ fontSize: "14px" }}>+91 92891 92873</span>
              </p>
              <p style={{ display: "flex", gap: "8px", fontSize: "13px" }}>
                <span style={{ color: "white" }}>
                  <FaMapMarkedAlt />{" "}
                </span>

                <span style={{ fontSize: "14px" }}>
                  Map :{" "}
                  <a
                    href="https://maps.app.goo.gl/74ufjPk837JUPDcq8"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Locate Us{" "}
                  </a>
                </span>
              </p>
              
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "-20px",
                }}
              >
                Follow Us :{" "}
                <p style={{ display: "flex", gap: "8px", color: "white" }}>
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/testifyi.india?igsh=c3ZpN2N2ZDg4cWVm"
                      )
                    }
                  >
                    <PiInstagramLogoBold />
                  </span>
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/testifyi.india?mibextid=LQQJ4d"
                      )
                    }
                  >
                    <RiFacebookFill />
                 
                </p>{" "}
              </p>

              
            </div>
          </div> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 style={{ textAlign: "center" }}>Disclaimer</h4>
            <p>
              The information provided in this IELTS webinar and course is for
              educational purposes only and should not be considered as
              personalized test preparation advice. While we strive to offer the
              most effective strategies and tips, individual results may vary.
              For personalized coaching and study plans, please consult with our
              certified trainers or academic counselors.
            </p>
          </div>

          <div>
            <hr className={Styles.Hr2} />
            <p onClick={() => Navigate("/")}>
              @ 2025 Testifyi.com All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>

      {/* Footer end */}

      {/* Bottom Fixed banner */}
      <section className={Styles.BootmFixedBanner}>
        <div>
          <div>
            <h4>
              <span>₹ 99</span> <span> ₹ 499</span>
            </h4>
            <p>Fri, 31st January, 7 PM</p>
          </div>
          <div>
            <button onClick={() => Scroll()}>Register Now</button>
          </div>
        </div>
      </section>
      {/* Bottom Fixed banner */}
    </>
  );
}
